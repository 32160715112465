import React from 'react'
import profile1 from '../../img/review/profile1.jpg';
import './review.css';

// import userProfile from "../../img/user.png";

const ReviewCard = (props) => {
    return (
        <>
            <div className='review-card' >
                <div className='review-card-c'>
                    <div className='review-card-stars'>
                        <ul>
                            <li>
                                <i class="ri-star-fill active"></i>
                            </li>
                            <li>
                                <i class="ri-star-fill active"></i>
                            </li>
                            <li>
                                <i class="ri-star-fill active"></i>
                            </li>
                            <li>
                                <i class="ri-star-fill active"></i>
                            </li>
                            <li>
                                <i class="ri-star-fill active"></i>
                            </li>
                        </ul>
                    </div>
                    <p>
                        “{props.description}”
                    </p>
                    <div className='review-profile'>
                        <div className='review-profile-image'>
                            <img src={props.image} alt="" />
                        </div>
                        <div className='review-profile-details'>
                            <h2>{props.name}</h2>
                            <p>{props.prosition}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const TeamList = [
    <ReviewCard 
    name="Ashara Chamodi"
    prosition = "Owner at Emi Fashion" 
    description="Fuchsius developed a fantastic system for Emi Fashion. Their team was professional, responsive, and technically skilled. The project was completed on time, and their post-development support has been excellent. Highly recommend!"
    image={profile1}
    ></ReviewCard>,
    // <ReviewCard></ReviewCard>
]

export default TeamList;