import Nav from "../components/nav/Nav2.js";
import Footer from "../components/footer/Footer2.js";
import "../assets/style/service.css";
import Image from "../img/theme-img/background.jpg";
import serviceFeature1 from "../img/theme-img/service_feature_1.svg";
import serviceFeature2 from "../img/theme-img/service_feature_2.svg";
import Button from '../components/common/button/Button1.js';
import Header from "../components/common/header/Header.js";

import ServiceList from "../components/service/ServiceList.js";
import ServiceContact from "../components/service/ServiceContact.js"

import Qa from "../components/service/Qa.js";
export default function Contact() {

  return (
    <>
      <Nav></Nav>
      <main>
        <Header image={Image} title='Web Development'></Header>
        <section className="service-detail-c1 container">
          <div className="service-detail-c1-c1">
            <h2>Web Development</h2>
            <div className="service-detail-p">
              <p>
                Create stunning, functional websites tailored to your brand. Build custom web applications for streamlined operations. Power online stores to boost sales and customer engagement.
              </p>
              <br />
              <p>
                Our team focuses on performance optimization to ensure fast loading times and seamless navigation. From simple landing pages to complex enterprise systems, we deliver user-friendly experiences that drive results. We also offer ongoing maintenance and support to keep your website running smoothly and up-to-date with the latest trends and security standards.
              </p>

            </div>
            <div className="service-benefits">
              <h2>Benefits With Our Service</h2>
              <div className="service-benefits-c">
                <div className="service-benefits-c1">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature1} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                    <h4>Flexible Solutions</h4>
                    <p>Completely grow multimedia based content before global scenarios.</p>
                  </div>
                </div>
                <div className="service-benefits-c2">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature2} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                    <h4>Customer Engagement</h4>
                    <p>Strengthen our company's engagement with customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-qa">
              <h2>Questions About Service</h2>
              <Qa
                title="Do you offer ongoing support and maintenance?"
                description="
              Absolutely. We provide various maintenance packages to ensure your website remains up-to-date and fully functional.
              "
              ></Qa>
              <Qa
                title="What is your process for starting a new project?"
                description="
              Our process begins with a consultation to understand your needs, followed by a proposal, design phase, development, testing, and finally, launch.
              "
              ></Qa>
              <Qa
                title="Can you integrate third-party services into my website?"
                description="
              Yes, we can integrate various third-party services like payment gateways, CRM systems, and more.
              "
              ></Qa>
              <Qa
                title="Do you offer any guarantees or warranties on your work?"
                description="
              Yes, we offer a warranty period post-launch to address any issues and ensure everything runs smoothly.
              "
              ></Qa>
            </div>

          </div>
          <div className="service-detail-c1-c2">
            <ServiceList></ServiceList>
            <ServiceContact></ServiceContact>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}
