import React, { useState, useRef } from "react";

export default function Qa(props) {
  const [isActive, setIsActive] = useState(false);
  const contentRef = useRef(null);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="service-qa-list-c">
      <div
        className={`service-qa-list ${isActive ? "active" : ""}`}
        onClick={handleClick}
      >
        <div className="service-qa-c">
          <p>{props.title}</p>
          <i className="ri-add-line"></i>
          <i className="ri-subtract-line"></i>
        </div>
        <div
          className="service-qa-d"
          ref={contentRef}
          style={{
            height: isActive ? contentRef.current.scrollHeight : "0px",
          }}
        >
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
}
