import Nav from "../components/nav/Nav2.js";
import Footer from "../components/footer/Footer2.js";
import "../assets/style/service.css";
import Image from "../img/theme-img/background.jpg";
import serviceFeature1 from "../img/theme-img/service_feature_1.svg";
import serviceFeature2 from "../img/theme-img/service_feature_2.svg";
import Button from '../components/common/button/Button1.js';
import Header from "../components/common/header/Header.js";

import ServiceList from "../components/service/ServiceList.js";
import ServiceContact from "../components/service/ServiceContact.js"

import Qa from "../components/service/Qa.js";
export default function Contact() {

  return (
    <>
      <Nav></Nav>
      <main>
        <Header image={Image} title='Digital Marketing'></Header>
        <section className="service-detail-c1 container">
          <div className="service-detail-c1-c1">
            <h2>Digital Marketing</h2>
            <div className="service-detail-p">
              <p>
              Elevate your online presence with our comprehensive digital marketing strategies. Boost search engine rankings and drive targeted traffic to maximize your business growth.
              </p>
              <br />
              <p>
                We craft tailored marketing plans that encompass SEO, content marketing, social media engagement, and pay-per-click advertising. Our approach focuses on enhancing visibility, building brand awareness, and converting leads into loyal customers.
              </p>
            </div>
            <div className="service-benefits">
              <h2>Benefits With Our Service</h2>
              <div className="service-benefits-c">
                <div className="service-benefits-c1">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature1} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                    <h4>Flexible Solutions</h4>
                    <p>Completely grow multimedia based content before global scenarios.</p>
                  </div>
                </div>
                <div className="service-benefits-c2">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature2} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                  <h4>Customer Engagement</h4>
                  <p>Strengthen our company's engagement with customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-qa">
              <h2>Questions About Service</h2>
              <Qa
                title="How can digital marketing help my business?"
                description="
              Digital marketing can increase your online visibility, drive more traffic to your website, generate leads, improve customer engagement, and ultimately boost sales.
              "
              ></Qa>
              <Qa
                title="Do you offer customized digital marketing strategies?"
                description="
              Yes, we create customized strategies tailored to your business goals, target audience, and industry.
              "
              ></Qa>
              <Qa
                title="Can you help with social media management?"
                description="
              Yes, we offer social media management services including content creation, scheduling, community management, and paid social advertising.
              "
              ></Qa>
              <Qa
                title="Do you provide content creation services?"
                description="
              Yes, our team can create high-quality content including blog posts, articles, infographics, videos, and social media posts.
              "
              ></Qa>
            </div>
          </div>
          <div className="service-detail-c1-c2">
            <ServiceList></ServiceList>
            <ServiceContact></ServiceContact>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}
