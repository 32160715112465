import React from "react";
import "./servicesList2.css";

import { Link as RouterLink } from 'react-router-dom';

export default function ServicesList2(props) {
  
  return (
    <>
      <div className="home-c3-items">
        <div className="home-c3-item-head-bg">
          <div className="home-c3-item-head-bg-c1">
            <div className="home-c3-item-icon">
              <i class={props.icon}></i>
            </div>
          </div>
          <div className="home-c3-item-head-bg-c2">
            <h1>{props.number}</h1>
          </div>
        </div>
        <div className="home-c3-item-c">
          <h2>{props.title}</h2>
          <p>{props.description}</p>
          <RouterLink to={props.path} className="">
            <div className="home-c3-b-c">
              Read More <i class="ri-arrow-right-line"></i>
            </div>
            <div className="home-c3-b-bg"></div>
          </RouterLink>
        </div>
        <div className="home-c3-bg-1"></div>
        <div className="home-c3-bg-2"></div>
      </div>
    </>
  );
}
