import Nav from "../components/nav/Nav2.js";
import Footer from "../components/footer/Footer2.js";
import "../assets/style/service.css";
import Image from "../img/theme-img/background.jpg";
import serviceFeature1 from "../img/theme-img/service_feature_1.svg";
import serviceFeature2 from "../img/theme-img/service_feature_2.svg";
import Button from '../components/common/button/Button1.js';
import Header from "../components/common/header/Header.js";

import ServiceList from "../components/service/ServiceList.js";
import ServiceContact from "../components/service/ServiceContact.js"

import Qa from "../components/service/Qa.js";
export default function Contact() {

  return (
    <>
      <Nav></Nav>
      <main>
        <Header image={Image} title='UI/UX Design'></Header>
        <section className="service-detail-c1 container">
          <div className="service-detail-c1-c1">
            <h2>UI/UX Design</h2>
            <div className="service-detail-p">
              <p>
              Craft intuitive user experiences. Design visually appealing and user-friendly interfaces.
              </p>
              <br />
              <p>
                We employ user-centered design principles to ensure that your digital products are both functional and aesthetically pleasing. From wireframes to final designs, our approach prioritizes seamless interactions and satisfying user journeys, leading to increased satisfaction and retention.
              </p>
            </div>
            <div className="service-benefits">
              <h2>Benefits With Our Service</h2>
              <div className="service-benefits-c">
                <div className="service-benefits-c1">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature1} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                    <h4>Flexible Solutions</h4>
                    <p>Completely grow multimedia based content before global scenarios.</p>
                  </div>
                </div>
                <div className="service-benefits-c2">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature2} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                  <h4>Customer Engagement</h4>
                  <p>Strengthen our company's engagement with customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-qa">
              <h2>Questions About Service</h2>
              <Qa
                title="What is UI/UX design?"
                description="UI (User Interface) design focuses on the look and layout of a product, including buttons, icons, spacing, and visual aesthetics. UX (User Experience) design focuses on the overall feel and functionality of the product, ensuring it provides a seamless and enjoyable user experience."
              ></Qa>
              <Qa
                title="What UI/UX design services does Fuchsius offer?"
                description="We offer a comprehensive range of UI/UX design services including user research, wireframing, prototyping, interface design, usability testing, and design audits.
"
              ></Qa>
              <Qa
                title="Why is UI/UX design important for my business?"
                description="Good UI/UX design improves user satisfaction, increases engagement, reduces bounce rates, and can lead to higher conversion rates. It ensures your product is intuitive and easy to use."
              ></Qa>
              <Qa
                title="Can you redesign an existing website or app?"
                description="Yes, we offer redesign services to improve the usability and visual appeal of your existing website or app, ensuring it meets modern design standards and user expectations."
              ></Qa>
            </div>

          </div>
          <div className="service-detail-c1-c2">
          <ServiceList></ServiceList>
          <ServiceContact></ServiceContact>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}
