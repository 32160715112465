import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import "remixicon/fonts/remixicon.css";
import ScrollToTop from './pages/ScrollToTop';

import Home from "./pages/Home";
import ServiceDigitalMarketing from "./pages/ServiceDigitalMarketing";
import ServiceITSupport from "./pages/ServiceITSupport";
import ServiceMobileAppDevelopment from "./pages/ServiceMobileAppDevelopment";
import ServiceSEO from "./pages/ServiceSEO";
import ServiceUI from "./pages/ServiceUI";
import ServiceWebDevelpment from "./pages/ServiceWebDevelpment";

function App() {

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <Router>
      <div>
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/service-digital-marketing" element={<ServiceDigitalMarketing />} />
          <Route path="/service-it-Support" element={<ServiceITSupport />} />
          <Route path="/service-mobile-app-development" element={<ServiceMobileAppDevelopment />} />
          <Route path="/service-seo" element={<ServiceSEO />} />
          <Route path="/service-ui-ux" element={<ServiceUI />} />
          <Route path="/service-web-develpment" element={<ServiceWebDevelpment />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
