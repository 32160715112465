import React from 'react';
import './button1.css';

export default function Button(props) {
  return (
    <>
      <a href={props.url} className='btn-1'>
        <div className='btn-1-content'>{props.text}  <i class="{props.icon}"></i></div>
        <div className='btn-1-animation'></div>
      </a>
    </>
  )
}
