import Nav from "../components/nav/Nav2.js";
import Footer from "../components/footer/Footer2.js";
import "../assets/style/service.css";
import Image from "../img/theme-img/background.jpg";
import serviceFeature1 from "../img/theme-img/service_feature_1.svg";
import serviceFeature2 from "../img/theme-img/service_feature_2.svg";
import Button from '../components/common/button/Button1.js';
import Header from "../components/common/header/Header.js";

import ServiceList from "../components/service/ServiceList.js";
import ServiceContact from "../components/service/ServiceContact.js"


import Qa from "../components/service/Qa.js";
export default function Contact() {
  
  return (
    <>
      <Nav></Nav>
      <main>
        <Header image={Image} title='Search Engine Optimization'></Header>
        <section className="service-detail-c1 container">
          <div className="service-detail-c1-c1">
            <h2>SEO</h2>
            <div className="service-detail-p">
              <p>
              Enhance your website's visibility on search engines with our expert SEO services. Improve organic search rankings and attract high-quality traffic to increase your business's online presence and growth.
              </p>
              <br />
              <p>
              We employ proven SEO techniques, including keyword optimization, on-page and off-page strategies, and content enhancement. Our goal is to drive relevant traffic to your site and increase your brand's visibility and credibility in search results.
              </p>
            </div>
            <div className="service-benefits">
              <h2>Benefits With Our Service</h2>
              <div className="service-benefits-c">
                <div className="service-benefits-c1">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature1} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                    <h4>Flexible Solutions</h4>
                    <p>Completely grow multimedia based content before global scenarios.</p>
                  </div>
                </div>
                <div className="service-benefits-c2">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature2} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                  <h4>Customer Engagement</h4>
                  <p>Strengthen our company's engagement with customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-qa">
              <h2>Questions About Service</h2>
              <Qa
                title="What is SEO and why is it important?"
                description="SEO (Search Engine Optimization) is the process of optimizing your website to rank higher in search engine results pages (SERPs). It is important because higher rankings increase visibility, drive more traffic, and can lead to higher conversions and sales."
              ></Qa>
              <Qa
                title="What SEO services does Fuchsius offer?"
                description="We offer a full range of SEO services including keyword research, on-page optimization, technical SEO, content creation, link building, and performance tracking and reporting."
              ></Qa>
              <Qa
                title="What is technical SEO and why is it important?"
                description="Technical SEO involves optimizing your website's infrastructure to make it easier for search engines to crawl and index your site. This includes improving site speed, mobile-friendliness, URL structure, and resolving any crawl errors."
              ></Qa>
              <Qa
                title="Can you guarantee a #1 ranking on Google?"
                description="No reputable SEO company can guarantee a #1 ranking on Google due to the complexities of search algorithms and competition. However, we commit to using best practices to improve your rankings and visibility."
              ></Qa>
            </div>

          </div>
          <div className="service-detail-c1-c2">
          <ServiceList></ServiceList>
          <ServiceContact></ServiceContact>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}
