import Nav from "../components/nav/Nav2.js";
import Footer from "../components/footer/Footer2.js";
import "../assets/style/service.css";
import Image from "../img/theme-img/background.jpg";
import serviceFeature1 from "../img/theme-img/service_feature_1.svg";
import serviceFeature2 from "../img/theme-img/service_feature_2.svg";
import Header from "../components/common/header/Header.js";

import ServiceList from "../components/service/ServiceList.js";
import ServiceContact from "../components/service/ServiceContact.js"


import Qa from "../components/service/Qa.js";
export default function Contact() {
  
  return (
    <>
      <Nav></Nav>
      <main>
        <Header image={Image} title='Mobile App Development'></Header>
        <section className="service-detail-c1 container">
          <div className="service-detail-c1-c1">
            <h2>Mobile App Development</h2>
            <div className="service-detail-p">
              <p>
              Create engaging mobile apps tailored to your audience. Build native apps for iOS and Android platforms to maximize reach and performance.
              </p>
              <br />
              <p>
              Our team focuses on performance optimization to ensure fast loading times and smooth operation. We also offer ongoing maintenance and support to keep your app updated with the latest features and security standards.
              </p>
              <br />
            </div>
            <div className="service-benefits">
              <h2>Benefits With Our Service</h2>
              <div className="service-benefits-c">
                <div className="service-benefits-c1">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature1} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                    <h4>Flexible Solutions</h4>
                    <p>Completely grow multimedia based content before global scenarios.</p>
                  </div>
                </div>
                <div className="service-benefits-c2">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature2} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                  <h4>Customer Engagement</h4>
                  <p>Strengthen our company's engagement with customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-qa">
              <h2>Questions About Service</h2>
              <Qa
                title="Do you offer app maintenance and updates after launch?"
                description="
              Yes, we provide ongoing maintenance and support packages to ensure your app stays up-to-date and runs smoothly.
              "
              ></Qa>
              <Qa
                title="Will my app be compatible with both iOS and Android?"
                description="
              We can develop apps specifically for iOS or Android, or create cross-platform apps that work seamlessly on both.
              "
              ></Qa>
              <Qa
                title="Can Fuchsius help with app store submissions?"
                description="
              Yes, we assist with the entire app store submission process, including compliance with guidelines and optimizing the listing for better visibility.
              "
              ></Qa>
              <Qa
                title="Can you integrate third-party services and APIs into my app?"
                description="
              Yes, we can integrate various third-party services, APIs, and SDKs to enhance your app’s functionality.
              "
              ></Qa>
            </div>

          </div>
          <div className="service-detail-c1-c2">
          <ServiceList></ServiceList>
          <ServiceContact></ServiceContact>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}
