import React from "react";
import './header.css';

export default function Header(props) {
  return (
    <>
      <section className="header">
        <div className="header-content container">
          <h1>{props.title}</h1>
          <div className="header-link">
            <ul>
              <li>
                <a href="">Home</a>
              </li>
              <li>
                <i class="ri-arrow-right-s-line"></i>
                <i class="ri-arrow-right-s-line"></i>
              </li>
              <li>
                <p>{props.title}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="header-background">
          <div className="header-image-color"></div>
          <img src={props.image} alt="" />
        </div>
      </section>
    </>
  );
}
