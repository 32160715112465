import Nav from "../components/nav/Nav2.js";
import Footer from "../components/footer/Footer2.js";
import "../assets/style/service.css";
import Image from "../img/theme-img/background.jpg";
import serviceFeature1 from "../img/theme-img/service_feature_1.svg";
import serviceFeature2 from "../img/theme-img/service_feature_2.svg";
import Button from '../components/common/button/Button1.js';
import Header from "../components/common/header/Header.js";

import ServiceList from "../components/service/ServiceList.js";
import ServiceContact from "../components/service/ServiceContact.js"


import Qa from "../components/service/Qa.js";
export default function Contact() {

  return (
    <>
      <Nav></Nav>
      <main>
        <Header image={Image} title='IT Support'></Header>
        <section className="service-detail-c1 container">
          <div className="service-detail-c1-c1">
            <h2>IT Support</h2>
            <div className="service-detail-p">
              <p>
                Ensure seamless operations with our expert IT support. Protect your business from cyber threats with robust security measures.
              </p>
              <br />
              <p>
                We provide comprehensive IT support services that include system monitoring, troubleshooting, and maintenance. Our team implements advanced security protocols to safeguard your data and ensure smooth, uninterrupted business operations.
              </p>
            </div>
            <div className="service-benefits">
              <h2>Benefits With Our Service</h2>
              <div className="service-benefits-c">
                <div className="service-benefits-c1">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature1} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                    <h4>Flexible Solutions</h4>
                    <p>Completely grow multimedia based content before global scenarios.</p>
                  </div>
                </div>
                <div className="service-benefits-c2">
                  <div className="service-benefits-c-c1">
                    <img src={serviceFeature2} alt="" />
                  </div>
                  <div className="service-benefits-c-c2">
                  <h4>Customer Engagement</h4>
                  <p>Strengthen our company's engagement with customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-qa">
              <h2>Questions About Service</h2>
              <Qa
                title="How can I contact Fuchsius for IT support?"
                description="
              You can contact us via phone, email, or through our website’s support portal. Our support team is available 24/7 to assist you.
              "
              ></Qa>
              <Qa
                title="What is your response time for IT support requests?"
                description="
              Our response time varies based on the urgency and type of issue. For critical issues, we aim to respond within 1 hour, while non-critical issues are addressed within 4-8 hours.
              "
              ></Qa>
              <Qa
                title="Do you offer remote IT support?"
                description="
              Yes, we provide remote IT support to quickly resolve issues without the need for an on-site visit, using secure remote access tools.
              "
              ></Qa>
              <Qa
                title="Do you provide support for software applications?"
                description="
              Yes, we provide support for a wide range of software applications, including installation, configuration, updates, and troubleshooting.
              "
              ></Qa>
            </div>

          </div>
          <div className="service-detail-c1-c2">
          <ServiceList></ServiceList>
          <ServiceContact></ServiceContact>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}
