import React, { useState } from 'react';
import temaImageNone from '../../img/team/user_icon.png';
import founder from '../../img/team/founder.jpg';
import projectManager from '../../img/team/project-manager.png';
import uiUxDesigner from '../../img/team/ui-ux.jpg';
import webDeveloper from '../../img/team/web-developer.png';
import hrManager from '../../img/team/hr.png';
import './teamList.css';

const TeamCard = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='team-card'>
            <div className='team-card-c'>
                <div className='team-card-background'>
                    <img src={props.profile} alt="Team" />
                </div>
                <div className='team-card-details'>
                    <div className='team-card-circle'>
                        <div className={`social-menu ${isOpen ? 'open' : ''}`}>
                            <button className="toggle-btn" onClick={toggleMenu}>
                                <i class="ri-apps-2-line"></i>
                            </button>
                            <div className="social-icons">
                                <a href={props.fb} className="social-icon facebook"><i class="ri-facebook-fill"></i></a>
                                <a href={props.x} className="social-icon twitter"><i class="ri-twitter-x-fill"></i></a>
                                <a href={props.insta} className="social-icon instagram"><i class="ri-instagram-line"></i></a>
                                <a href={props.in} className="social-icon linkedin"><i class="ri-linkedin-fill"></i></a>
                                <a href={props.github} className="social-icon youtube"><i class="ri-github-fill"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className='team-card-box'>
                        <div>
                            <h1>{props.name}</h1>
                            <p>{props.position}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TeamList = [
    <TeamCard name={
        "Tharindu Kulasinghe"
    } position={
        "CEO/ Founder"
    } fb={
        ""
    } x={
        ""
    } insta={
        ""
    } in={
        ""
    } github={
        ""
    } profile={
        founder
    } />,

    <TeamCard name={
        "Thakshila Navodani"
    } position={
        "UI/UX Designer"
    } fb={
        ""
    } x={
        ""
    } insta={
        ""
    } in={
        ""
    } github={
        ""
    } profile={
        uiUxDesigner
    } />,
<TeamCard name={
        "Nadeesha Jayawickrama"
    } position={
        "Project Manager"
    } fb={
        ""
    } x={
        ""
    } insta={
        ""
    } in={
        ""
    } github={
        ""
    } profile={
        projectManager
    } />,
   
    <TeamCard name={
        "Subhath Chathuranga"
    } position={
        "Web Developer"
    } fb={
        ""
    } x={
        ""
    } insta={
        ""
    } in={
        ""
    } github={
        ""
    } profile={
        webDeveloper
    } />,

    <TeamCard name={
        "Kanchana Bandara"
    } position={
        "HR Manager"
    } fb={
        ""
    } x={
        ""
    } insta={
        ""
    } in={
        ""
    } github={
        ""
    } profile={
        hrManager
    } />,
     

]

export default TeamList;
