import React from 'react'
import imageTest from '../../img/1.jpg';
import './servicesList1.css';

import appDevelopment from "../../img/service/app-development.png"
import digitalMarketing from "../../img/service/digital-marketing.png"
import seo from "../../img/service/seo.png"
import technicalSupport from "../../img/service/technical-support.png"
import uxDesign from "../../img/service/ux-design.png"
import webDevelopement from "../../img/service/web-developement.png"

import { Link as RouterLink } from 'react-router-dom';

const items = [
    <div className="home-c1-item">
        <div className='home-c1-content'>
            <div className='home-c1-c-image'>
                <img src={webDevelopement} alt="" />
            </div>
            <h2>Web Development</h2>
            <p>Create stunning, functional websites tailored to your brand. Build custom web applications for streamlined operations. Power online stores to boost sales and customer engagement</p>
            <div className='home-c1-c-button'>
                <RouterLink to="/service-web-develpment">
                    <div className='home-c1-c-buttom-c'>
                        <i class="ri-arrow-right-line"></i>
                    </div>
                    <div className='home-c1-c-buttom-ani'></div>
                </RouterLink>
            </div>
        </div>
        <div className="background"></div>
    </div>,
    <div className="home-c1-item">
        <div className='home-c1-content'>
            <div className='home-c1-c-image'>
                <img src={appDevelopment} alt="" />
            </div>
            <h2>Mobile App Development</h2>
            <p>Create engaging mobile apps tailored to your audience. Build native apps for iOS and Android platforms to maximize reach and performance</p>
            <div className='home-c1-c-button'>
                <RouterLink to="/service-mobile-app-development">
                    <div className='home-c1-c-buttom-c'>
                        <i class="ri-arrow-right-line"></i>
                    </div>
                    <div className='home-c1-c-buttom-ani'></div>
                </RouterLink>
            </div>
        </div>
        <div className="background"></div>
    </div>,
    <div className="home-c1-item">
        <div className='home-c1-content'>
            <div className='home-c1-c-image'>
                <img src={uxDesign} alt="" />
            </div>
            <h2>UI/UX Design</h2>
            <p>Craft intuitive user experiences. Design visually appealing and user-friendly interfaces </p>
            <div className='home-c1-c-button'>
                <RouterLink to="/service-ui-ux">
                    <div className='home-c1-c-buttom-c'>
                        <i class="ri-arrow-right-line"></i>
                    </div>
                    <div className='home-c1-c-buttom-ani'></div>

                </RouterLink>
            </div>
        </div>
        <div className="background"></div>
    </div>,

    <div className="home-c1-item">
        <div className='home-c1-content'>
            <div className='home-c1-c-image'>
                <img src={digitalMarketing} alt="" />
            </div>
            <h2>Digital Marketing</h2>
            <p>Elevate your online presence with our comprehensive digital marketing strategies. Boost search engine rankings and drive targeted traffic to maximize your business growth</p>
            <div className='home-c1-c-button'>
                <RouterLink to="/service-digital-marketing">
                    <div className='home-c1-c-buttom-c'>
                        <i class="ri-arrow-right-line"></i>
                    </div>
                    <div className='home-c1-c-buttom-ani'></div>

                </RouterLink>
            </div>
        </div>
        <div className="background"></div>
    </div>,
    <div className="home-c1-item">
        <div className='home-c1-content'>
            <div className='home-c1-c-image'>
                <img src={technicalSupport} alt="" />
            </div>
            <h2>IT Support</h2>
            <p>Ensure seamless operations with our expert IT support. Protect your business from cyber threats with robust security measures</p>
            <div className='home-c1-c-button'>
                <RouterLink to="/service-it-Support">
                    <div className='home-c1-c-buttom-c'>
                        <i class="ri-arrow-right-line"></i>
                    </div>
                    <div className='home-c1-c-buttom-ani'></div>
                </RouterLink>
            </div>
        </div>
        <div className="background"></div>
    </div>,
    <div className="home-c1-item">
        <div className='home-c1-content'>
            <div className='home-c1-c-image'>
                <img src={seo} alt="" />
            </div>
            <h2>SEO</h2>
            <p>Enhance your website's visibility on search engines with our expert SEO services. Improve organic search rankings and attract high-quality traffic to increase your business's online presence and growth</p>
            <div className='home-c1-c-button'>
                <RouterLink to="/service-seo">
                    <div className='home-c1-c-buttom-c'>
                        <i class="ri-arrow-right-line"></i>
                    </div>
                    <div className='home-c1-c-buttom-ani'></div>
                </RouterLink>
            </div>
        </div>
        <div className="background"></div>
    </div>

];

export default items;