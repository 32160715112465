import React from "react";
import "./footer.css";

import { useNavigate } from 'react-router-dom';

import { Link as RouterLink } from 'react-router-dom';


export default function Footer() {

  const navigate = useNavigate();
  const handleNavigate = (page) => () => {
    navigate(`../?p=${page}`);
  };

  return (
    <>
      <footer>
        <div className="footer-wave">
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>
          <div className="footer-wave-list"></div>

        </div>
        <div className="footer-c1 container">
          <div className="footer-c1-c1">
            <div className="footer-c1-c1-c1">
              <div className="footer-title">
                <h1>ABOUT COMPANY</h1>
              </div>
              {/* <div className="footer-company-logo mt20">
                <img src={logo2} alt="" />
              </div> */}
              <p className="mt20">
                Professionally redefine transparent ROI through low-risk
                high-yield imperatives. Progressively create empowered. cost
                effective users via team driven.
              </p>
              <div className="footer-social-media mt20">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/share/EeEpZuJjRjjDt2PF/?mibextid=qi2Omg">
                      <i class="ri-facebook-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i class="ri-twitter-x-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/fuchsius">
                      <i class="ri-linkedin-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/fuchsius_official?igsh=NnFnNjBnN3B4bXM0">
                      <i class="ri-instagram-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i class="ri-whatsapp-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-c1-c1-c2">
              <div className="footer-title">
                <h1>QUICK LINKS</h1>
              </div>
              <div className="footer-links mt20">
                <ul>
                  <li>
                    <a onClick={handleNavigate('about-us')}>
                      <i class="ri-arrow-right-s-line"></i> About Us
                    </a>
                  </li>
                  <li>
                    <a onClick={handleNavigate('our-team')}>
                      <i class="ri-arrow-right-s-line"></i> Meet Our Team
                    </a>
                  </li>
                  <li>
                    <a onClick={handleNavigate('contact')}>
                      <i class="ri-arrow-right-s-line"></i> Our Projects
                    </a>
                  </li>
                  <li>
                    <a onClick={handleNavigate('service')}>
                      <i class="ri-arrow-right-s-line"></i> Services
                    </a>
                  </li>
                  <li>
                    <a onClick={handleNavigate('contact')}>
                      <i class="ri-arrow-right-s-line"></i> Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-c1-c2">
            <div className="footer-c1-c2-c1">
              <div className="footer-title">
                <h1>IT SERVICES</h1>
              </div>
              <div className="footer-links mt20">
                <ul>
                  <li>
                    <RouterLink to="/service-web-develpment">
                      <i class="ri-arrow-right-s-line"></i> Web Development
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/service-mobile-app-development">
                      <i class="ri-arrow-right-s-line"></i> Mobile App Development
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/service-digital-marketing">
                      <i class="ri-arrow-right-s-line"></i> Digital Marketing
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/service-it-Support">
                      <i class="ri-arrow-right-s-line"></i> IT Support
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink to="/service-seo">
                      <i class="ri-arrow-right-s-line"></i> SEO
                    </RouterLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-c1-c2-c2">
              <div className="footer-title">
                <h1>NewsLetter</h1>
              </div>
              <div className="footer-newsletter mt20">
                <p>
                  Stay updated with the latest industry trends, company news, and exclusive offers. Subscribe to our newsletter and be the first to know

                </p>
                <div className="footer-n-input">
                  <input type="text" placeholder="Enter your email" />
                  <input type="submit" value="Subscribe" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-c2">
          <div className="footer-c2-c container">
            <div className="footer-c2-c1">
              <p>© 2024 <span>Fuchsius</span> | IT & Creative Solutions. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
