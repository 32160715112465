import React from 'react'
import { Link as RouterLink } from 'react-router-dom';


export default function ServiceList() {
    return (
        <>
            <div className="service-d-all">
                <h2>All Services</h2>
                <ul>
                    <li>
                        <RouterLink to="/service-web-develpment">
                            Web Development<i class="ri-arrow-right-line"></i>
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/service-mobile-app-development">
                            Mobile App Development<i class="ri-arrow-right-line"></i>
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/service-ui-ux">
                            UI/UX Design<i class="ri-arrow-right-line"></i>
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/service-digital-marketing">
                            Digital Marketing<i class="ri-arrow-right-line"></i>
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/service-it-Support">
                            IT Support<i class="ri-arrow-right-line"></i>
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/service-seo">
                            SEO<i class="ri-arrow-right-line"></i>
                        </RouterLink>
                    </li>
                </ul>
            </div>
        </>
    )
}
