import React from 'react'
import titleShape from '../../../img/theme-img/title_shape_1.svg'
import './title.css';

export default function Title(props) {
    return (
        <>
            <div className='title'>
                <img src={titleShape} alt="" />
                <h4>{props.text}</h4>
            </div>
        </>
    )
}
